import request from '@/router/axios';
import { pvhApiBase } from '@/config/env'

//列表
export const purOrderAgentList = (page, pageSize, vendor, businessnoin, businessnoout, code, batch, color, po, beginTime, endTime, id, otherStatus, traingStatus, UTbegin, UTend, sort, remark, SeaCode) => {
    return request({
        url: pvhApiBase + "Tracing/GetAgentPurchaseOrders",
        method: "get",
        params: {
            "businessnoin": businessnoin,
            "businessnoout": businessnoout,
            "code": code,
            "batch": batch,
            "color": color,
            "vendor": vendor,
            "po": po,
            "beginOrderDate": beginTime,
            "endOrderDate": endTime,
            "id": id,
            "otherStatus": otherStatus,
            "traingStatus": traingStatus,
            "UTbegin": UTbegin,
            "UTend": UTend,
            "sort": sort,
            "remark": remark,
            "SeaCode": SeaCode,
            "page": page,
            "pageSize": pageSize
        }
    })
}

//列表
export const purOrderList = (page, pageSize, vendor, businessnoin, businessnoout, code, batch, color, po, beginTime, endTime, id, otherStatus, traingStatus, UTbegin, UTend, sort, remark, SeaCode) => {
    return request({
        url: pvhApiBase + "Tracing/GetPurchaseOrders",
        method: "get",
        params: {
            "businessnoin": businessnoin,
            "businessnoout": businessnoout,
            "code": code,
            "batch": batch,
            "color": color,
            "vendor": vendor,
            "po": po,
            "beginOrderDate": beginTime,
            "endOrderDate": endTime,
            "id": id,
            "otherStatus": otherStatus,
            "traingStatus": traingStatus,
            "UTbegin": UTbegin,
            "UTend": UTend,
            "sort": sort,
            "remark": remark,
            "SeaCode": SeaCode,
            "page": page,
            "pageSize": pageSize
        }
    })
}

//Excel
export const purOrderListExcel = (page, pageSize, vendor, businessnoin, businessnoout, code, batch, color, po, beginTime, endTime, id, status, otherStatus, traingStatus, UTbegin, UTend, sort, remark, SeaCode) => {
    return request({
        url: pvhApiBase + "Tracing/GetPurchaseOrdersExcel",
        method: "get",
        responseType: "blob",
        headers: {
            'Content-Type': 'application/json; application/octet-stream',
        },
        params: {
            "businessnoin": businessnoin,
            "businessnoout": businessnoout,
            "code": code,
            "batch": batch,
            "color": color,
            "vendor": vendor,
            "po": po,
            "beginOrderDate": beginTime,
            "endOrderDate": endTime,
            "id": id,
            "status": status,
            "otherStatus": otherStatus,
            "traingStatus": traingStatus,
            "UTbegin": UTbegin,
            "UTend": UTend,
            "sort": sort,
            "remark": remark,
            "SeaCode": SeaCode,
            "page": page,
            "pageSize": pageSize
        }
    })
}

//Excel
export const purOrderAgentListExcel = (page, pageSize, vendor, businessnoin, businessnoout, code, batch, color, po, beginTime, endTime, id, status, otherStatus, traingStatus, UTbegin, UTend, sort, remark, SeaCode) => {
    return request({
        url: pvhApiBase + "Tracing/GetPurchaseOrdersAgentExcel",
        method: "get",
        responseType: "blob",
        headers: {
            'Content-Type': 'application/json; application/octet-stream',
        },
        params: {
            "businessnoin": businessnoin,
            "businessnoout": businessnoout,
            "code": code,
            "batch": batch,
            "color": color,
            "vendor": vendor,
            "po": po,
            "beginOrderDate": beginTime,
            "endOrderDate": endTime,
            "id": id,
            "status": status,
            "otherStatus": otherStatus,
            "traingStatus": traingStatus,
            "UTbegin": UTbegin,
            "UTend": UTend,
            "sort": sort,
            "remark": remark,
            "SeaCode": SeaCode,
            "page": page,
            "pageSize": pageSize
        }
    })
}

//获取excel中订单信息
export const fileOrderList = (params) => {
    return request({
        url: pvhApiBase + "Tracing/GetFileOrderList",
        method: "post",
        data: {
            ...params
        }
    })
}

//数据导入数据库
export const importPurchaseOrderList = (params) => {
    return request({
        url: pvhApiBase + "Tracing/ImportPurchaseOrderList",
        method: "post",
        data: {
            ...params
        }
    })
}

//根据采购id获取关联销售信息
export const SoListByPoId = (id) => {
    return request({
        url: pvhApiBase + "Tracing/GetSaleOrderListByPOId",
        method: "get",
        params: {
            "id": id
        }
    })
}



//根据采购明细id获取关联销售信息
export const SoListByPoDetailId = (id) => {
    return request({
        url: pvhApiBase + "Tracing/GetSaleOrderListByPoDetailId",
        method: "get",
        params: {
            "id": id
        }
    })
}

//根据合同id获取采购信息
export const POListById = (id) => {
    return request({
        url: pvhApiBase + "Tracing/GetPurchaseOrderListByContractId",
        method: "get",
        params: {
            "id": id
        }
    })
}

//获取下级所有列表
export const AllPOList = (PurchaseId) => {
    return request({
        url: pvhApiBase + "Tracing/GetTraingAllPurchaseOrders",
        method: "get",
        params: {
            "PurchaseId": PurchaseId
        }
    })
}

//获取po及详情信息
export const PoAndDetailList = (page, pageSize, vendor, code, po, color, no_SeaCodeVal,seaCode, beginTime, endTime) => {
    return request({
        url: pvhApiBase + "Tracing/GetPoAndDetailList",
        method: "get",
        params: {
            "code": code,
            "po": po,
            "color": color,
            "vendor": vendor,
            "no_SeaCodeVal": no_SeaCodeVal,
            "seaCode": seaCode,
            "beginOrderDate": beginTime,
            "endOrderDate": endTime,
            "page": page,
            "pageSize": pageSize
        }
    })
}

//新增
export const addPOHead = (params) => {
    return request({
        url: pvhApiBase + "Tracing/PostPurchaseOrder",
        method: "post",
        data: {
            ...params
        }
    })
}

//编辑
export const editHead = (params) => {
    return request({
        url: pvhApiBase + "Tracing/PutPurchaseOrder",
        method: "put",
        data: {
            ...params
        }
    })
}

//恢复
export const recoverHead = (id) => {
    return request({
        url: pvhApiBase + "Tracing/RecoverPurchaseOrder",
        method: "delete",
        params: {
            id
        }
    })
}

//删除
export const delHead = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeletePurchaseOrder",
        method: "delete",
        params: {
            id
        }
    })
}

//新增
export const addPOBody = (params) => {
    return request({
        url: pvhApiBase + "Tracing/PostPurchaseOrderDetail",
        method: "post",
        data: {
            ...params
        }
    })
}

//编辑
export const editBody = (params) => {
    return request({
        url: pvhApiBase + "Tracing/PutPurchaseOrderDetail",
        method: "put",
        data: {
            ...params
        }
    })
}

//删除
export const delBody = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeletePurchaseOrderDetail",
        method: "delete",
        params: {
            id
        }
    })
}





//vendorvection 供应商类别
export const vendorvectionlist = () => {
    return request({
        url: pvhApiBase + "ProcessManagement/GetCorporations",
        method: "get",
    })

}



//申报完成
export const DecCompleted = (id, status) => {
    return request({
        url: pvhApiBase + "Tracing/PutDecCompletedPO",
        method: "put",
        params: {
            "id": id,
            "status": status
        }
    })
}

//取消申报完成
export const UnDecCompleted = (id, isBodyType) => {
    return request({
        url: pvhApiBase + "Tracing/PutUnDecCompletedPO",
        method: "put",
        params: {
            "id": id,
            "isBodyType": isBodyType
        }
    })
}

//获取销售单详情
export const POInfo = (id, orderId, brandBusinessId, vendorSectionId) => {
    return request({
        url: pvhApiBase + "Tracing/GetPurchaseOrderCanRead",
        method: "get",
        params: {
            "id": id,
            "orderId": orderId,
            "brandBusinessId": brandBusinessId,
            "vendorSectionId": vendorSectionId
        }
    })
}


export function getDate(d) {
    var date = new Date(d); //日期对象
    var yTime = date.getFullYear();
    var MTime = date.getMonth() + 1;
    var dTime = date.getDate();
    var hTime = date.getHours();
    var Ftime = date.getMinutes();
    var sTime = date.getSeconds();
    if (MTime < 10) {
        MTime = "0" + MTime;
    }
    if (dTime < 10) {
        dTime = "0" + dTime;
    }
    if (hTime < 10) {
        hTime = "0" + hTime;
    }
    if (Ftime < 10) {
        Ftime = "0" + Ftime;
    }
    if (sTime < 10) {
        sTime = "0" + sTime;
    }
    return yTime + "-" + MTime + "-" + dTime + " " + hTime + ":" + Ftime ;
}

export function getDateYYYMMDD(d) {
    var date = new Date(d); //日期对象
    var yTime = date.getFullYear();
    var MTime = date.getMonth() + 1;
    var dTime = date.getDate();
    if (MTime < 10) {
        MTime = "0" + MTime;
    }
    if (dTime < 10) {
        dTime = "0" + dTime;
    }
    return yTime + "-" + MTime + "-" + dTime ;
}
export const PutPurchaseOrderDetail_Copy = (params) => {
    return request({
        url: pvhApiBase + "Tracing/PutPurchaseOrderDetail_Copy",
        method: "put",
        data: {
            ...params
        }
    })
}

export const PutPurchaseOrderDetail_CancelCopy = (id) => {
    return request({
        url: pvhApiBase + "Tracing/PutPurchaseOrderDetail_CancelCopy",
        method: "delete",
        params: {
            id
        }
    })
}

export const GetTraingAllPOPendingInfos = (PurchaseId) => {
    return request({
        url: pvhApiBase + "Tracing/GetTraingAllPOPendingInfos",
        method: "get",
        params: {
            PurchaseId
        }
    })
}



//获取棉包详情
export const BaleTracing = (CorporationId, PickingNo) => {
    return request({
        url: pvhApiBase + "Tracing/GetBaleTracingDetailInfo",
        method: "get",
        params: {
            "CorporationId": CorporationId,
            "PickingNo": PickingNo
        }
    })
}

export const PoRelationSoidInfo = (poId, poDetailId) => {
    return request({
        url: pvhApiBase + "Tracing/GetPoRelationSoidInfo",
        method: "get",
        params: {
            "poId": poId,
            "poDetailId": poDetailId
        }
    })
}